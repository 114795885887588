
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import Vue3Html2pdf from 'vue3-html2pdf';
import Certificate from '@/layout/extras/Certificate.vue';
import { VueCookieNext } from 'vue-cookie-next';
import moment from 'moment';
import axios from 'axios';
import { ElNotification } from 'element-plus';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'certificate-information',
  components: {
    Certificate,
    Datatable,
    Vue3Html2pdf,
  },
  data() {
    return {
      moment: '' as any,
      batch: {
        entity_id: '',
        tranche_id: '',
        institute_id: '',
        course_id: '',
        batch_id: '',
      },
      api_url: '',
      name: '',
      imageLink: this.VUE_APP_API_URL + '/storage/images/seip.jpg',
      $refs: {} as any,
      tranches_info: [],
      entityInfos: [],
      batchList: [],
      traineeList: [] as any,
      instituteList: [],
      courseList: [],
      empDistricts: [],

      image_path: '',
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '150px',
        },
        {
          name: 'Sl',
          key: 'sl',
          sortable: true,
          width: '5px',
        },
        {
          name: 'Photo',
          key: 'photo',
          sortable: true,
        },
        {
          name: 'Ref No.',
          key: 'ref_no',
          sortable: true,
        },
        {
          name: ' Reg No.',
          key: 'reg_no',
          sortable: true,
        },
        {
          name: 'Trainee Name	',
          key: 'name',
          sortable: true,
        },

        {
          name: 'Mobile',
          key: 'mobile',
          sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },
        {
          name: 'Gender',
          key: 'gender',
          sortable: true,
        },

        {
          name: 'Certified',
          key: 'certified',
          sortable: true,
        },
        {
          name: 'Certification Date',
          key: 'certification_date',
          sortable: true,
        },
      ],
      loading: false,
      certificateList: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      entity_id: '',
      componentKey: 0,
    };
  },
  async created() {
    this.moment = moment;
    this.api_url = this.VUE_APP_API_URL;
    await this.getEntityInfos();
    await this.getTranches();
    this.entity_id = VueCookieNext.getCookie('_entity_id');
    console.log(this.entity_id);

    await this.getEntityInfo();
  },
  methods: {
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>', duration: 0
      });
      let formData = new FormData();

      formData.set('entity_id', this.batch.entity_id);
      formData.set('course_info_id', this.batch.course_id);
      formData.set('training_institute_id', this.batch.institute_id);
      formData.set('batch_info_id', this.batch.batch_id);
      formData.set('tranche_id', this.batch.tranche_id);

      // console.log(JSON.stringify(this.st));

      await ApiService.post(
        'assessment/certification/certificationpdf',
        formData
      )
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            ElNotification.closeAll();
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
          ElNotification.closeAll();
        });
    },

    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>', duration: 0
      });
      let formData = new FormData();

      formData.set('entity_id', this.batch.entity_id);
      formData.set('course_info_id', this.batch.course_id);
      formData.set('training_institute_id', this.batch.institute_id);
      formData.set('batch_info_id', this.batch.batch_id);
      formData.set('tranche_id', this.batch.tranche_id);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/assessment/certification/certificationExport`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Training Certification List.xlsx');
          document.body.appendChild(link);
          link.click();
          ElNotification.closeAll();
        })
        .catch((response) => {
          console.log(response);
          ElNotification.closeAll();
        });
    },

    generateReport(data) {
      this.name = data.trainee_name;
      console.log(this.name);
      this.$refs.html2Pdf.generatePdf();
    },
    async getEntityInfo() {
      ApiService.get('entity/show/' + this.entity_id)
        .then((response) => {
          this.image_path =
            this.VUE_APP_API_URL +
            response.data.data?.attach_file_info?.file_path;
          console.log(this.image_path);
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getTraineeList() {
      this.loading = true;
      if (!this.batch.batch_id) {
        this.loading = false;
        Swal.fire({
          title: 'Warning!',
          html: 'Please select all required fields.',
          icon: 'warning',
        });
      } else {
        await ApiService.get(
          'assessment/certification/list?entity_id=' +
          this.batch.entity_id +
          '&course_info_id=' +
          this.batch.course_id +
          '&training_institute_id=' +
          this.batch.institute_id +
          '&batch_info_id=' +
          this.batch.batch_id +
          '&tranche_id=' +
          this.batch.tranche_id
        )
          .then((response) => {
            this.loading = false;
            this.certificateList = true;
            this.traineeList = response.data.data;
            this.componentKey += 1;
            console.log(this.traineeList);
            //console.log(this.traineeList);
          })
          .catch((response) => {
            this.loading = false;
            console.log(response);
          });
      }
    },

    async getEntityInfos() {
      this.loading = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get(
        this.VUE_APP_GET_ENTITY_LIST_API + '?entity_id=' + entity_id
      )
        .then((response) => {
          this.loading = false;
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getTranches() {
      this.loading = true;
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.loading = false;
          this.tranches_info = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async batchInfo() {
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_BATCH_LIST_API +
        '?entity_id=' +
        this.batch.entity_id +
        '&course_info_id=' +
        this.batch.course_id +
        '&institute_info_id=' +
        this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.batchList = response.data.data;
          this.courseInfoData = true;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getTrainingInstitute() {
      this.loading = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
        '?entity_id=' +
        this.batch.entity_id +
        '&institute_info_id=' +
        institute_info_id
      )
        .then((response) => {
          this.loading = false;
          this.instituteList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async courseInfo() {
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_COURSE_SEARCH_LIST_API +
        '?entity_id=' +
        this.batch.entity_id +
        '&tranche=' +
        this.batch.tranche_id +
        '&institute_info_id=' +
        this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.courseList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    showDetails() {
      this.certificateList = true;
    },
  },
});
